import React, { useEffect, useState,useCallback  } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Toolbar,
  AppBar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { suvegaImage as suvega, manImage as man } from "../../assets/index";
import AppMenu from "./menu";
import { useHistory } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import NotificationsIcons from "@mui/icons-material/Notifications";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import _ from "lodash";
import * as Fields from "../../sharedComponents";
import { useSelector } from "react-redux";
import { EditUserProfile } from "../../pages/profile/editProfile";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AlertMessage from "../alertmessage/AlertMessage";
// import { Scrollbars } from "react-custom-scrollbars";
import { Image,deveation } from "../../assets/index";
import CircularLoading from "../../components/loader/circularLoading";
import CryptoJS from "crypto-js";
// import { Scrollbars } from 'react-custom-scrollbars-2';
import { Scrollbars } from "react-custom-scrollbars";
import RouteDeviationMap from "./RouteDeviationMap";
import { getMessaging, deleteToken } from "firebase/messaging";

const useStyles = makeStyles((theme) => ({
  appBar: {

    backgroundColor: "#366E93",
  },
  grow: {
    flexGrow: "1",
    marginLeft: theme.spacing(2),
  },
  image: {
    width: "120px",
    marginRight: theme.spacing(6),
    cursor:`pointer`
  },
  name: {
    marginRight: theme.spacing(1),
    fontSize: "0.9rem",
  },
  // header: {
  //   position: 'fixed',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   zIndex: 1000,
  //   backgroundColor: '#fff',
  //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  //   marginBottom:'100px'

  // },
  // toolbarOffset: theme.mixins.toolbar,
}));

export default function Header(props) {
  const classes = useStyles();
  const [auth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userName, setUserName] = React.useState(null);
  const [profilepicBase64, setProfilepicBase64] = React.useState(null);
  const open = Boolean(anchorEl);
  const [alertData, setAlertData] = useState();
  const [alertProfileData, setAlertProfileData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [spinner, setSpinner] = useState(false);
  const [page, setPage] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const formFields = {
    allVehicles: {
      name: "allVehicles",
      // label: "All Vehicles",
      value: { label: "", value: "" },
      options: [],
      validationRequired: false,
      topLabel: true,
      isValid: true,
      placeholder: "All Vehicles",
      // errorMsg: "Please select All Vehicles",
      allowClear: true,
    },
    allCategory: {
      name: "allCategory",
      // label: "All Category",
      value: { label: "", value: "" },
      options: [],
      validationRequired: false,
      topLabel: true,
      isValid: true,
      placeholder: "Categories",
      // errorMsg: "Please select All Vehicles",
      allowClear: true,
    },
    subCategory: {
      name: "subCategory",
      value: { label: "", value: "" },
      options: [],
      validationRequired: false,
      topLabel: true,
      isValid: true,
      placeholder: "Sub Categories",
      // errorMsg: "Please select All Vehicles",
      allowClear: true,
    },
  };
  let profileFields = {
    name: {
      name: "name",
      label: "Name",
      topLabel: true,
      value: "",
      placeholder: "Enter your name",
      errorMsg: "Please enter your name",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
  };
  // const [profileData, setProfileData] = useState(_.cloneDeep(profileFields));
  // const inputChangeHandler = (value, name) => {
  //   let newFields = _.cloneDeep(profileData)
  //   profileData[name]["value"] = value
  //   setProfileData(newFields)
  //   // setProfileData((prevData) => ({
  //   //   ...prevData,
  //   //   [name]: {
  //   //     ...prevData[name],
  //   //     value: value,
  //   //     isValid: true,
  //   //   },
  //   // }));
  // };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertProfileData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertProfileData(alert);
  };
  const closeAlertMsg = () => {
    let error = _.cloneDeep(alertProfileData);
    error.open = false;
    error.severity = "";
    error.message = "";
    setAlertProfileData(error);
    // window.location.reload();
  };
  const handleChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      // setUploadingFile(uploadedFile);
      // setFile(URL.createObjectURL(uploadedFile));
    }
  };
  const [field, setField] = useState(_.cloneDeep(formFields));
  let history = useHistory();
  const [drawer, setDrawer] = React.useState({
    right: false,
  });
  const storeData = useSelector((state) => state.NOTIFICATIONS);
  const [profileOpen, setProfileOpen] = useState(false);
  const [routeDeviatePopUp,setRouteDeviatePopUp] = useState(false)
  const [routeDeviateData,setRouteDeviateData] = useState({
    orderId:``,
    alertTime:``,
    regiNo:``
  })
  const decryptData = (encryptedData, secretKey) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      // If decryption fails and empty data is returned
      if (!decryptedData) {
        throw new Error("Decryption failed");
      }

      return JSON.parse(decryptedData);
    } catch (error) {
      console.error("Error during decryption:", error);
      return null;
    }
  };

  // Function to retrieve encrypted data from sessionStorage and decrypt it
  const retrieveData = (key, secretKey) => {
    const encryptedData = sessionStorage.getItem(key);
    if (!encryptedData) return null; // Return null if no data found
    return decryptData(encryptedData, secretKey);
  };
  const storedProfile = retrieveData("profile", "my_profile");
  useEffect(() => {
    // setSpinner(true);
    setLoading(true);
    let newField = _.cloneDeep(field);
    if (global?.session.get("profile")) {
      let userProfile = storedProfile;
      if (userProfile) {
        setUserName(userProfile?.name);
        if (userProfile?.profile_pic) {
          getProfileImage(userProfile?.profile_pic);
        }
      }
    }
    // let data = {
    //   page: 1,
    //   truck_id: "",
    //   alert_type_id: "",
    //   alert_subtype_id: "",
    // };
    // let promise1 = global.service.getAllVehicles({ownVehicles: true, marketVehicles: true });
    // let promise2 = global.service.getAllCategories();
    // let promise3 = global.service.getAllAlerts(data);
    // Promise.all([promise1, promise2, promise3]).then((res) => {
    //   setSpinner(false);
    //   if (res && res) {
    //     let options = res[0]?.vehicles?.map((i) => {
    //       return { label: i?.registrationNumber, value: i?.id };
    //     });
    //     newField["allVehicles"]["options"] = options;

    //     let getAllCategoriesOptions = res[1]?.data?.map((i) => {
    //       return { label: i?.alert_name, value: i?.id };
    //     });
    //     newField["allCategory"]["options"] = getAllCategoriesOptions;
    //     setAlertData(res[2].data);
    //     setField(newField);
    //   }
    // });
    if (storeData && storeData) {
      newField["allVehicles"]["options"] = storeData?.ALL_VEHICLES;
      newField["allCategory"]["options"] = storeData?.ALL_CATEGORY;
      setAlertData(storeData?.response);
      setField(newField);
    }
  }, []);

  const getProfileImage = (imagePath) => {
    global.service
      .getFleetOwnerImage({ imagePath: imagePath })
      .then((response) => {
        if (response.status) {
          let base64string = response.result;
          setProfilepicBase64(base64string);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [menuPosition, setMenuPosition] = useState(null);
  const handleItemsClick = (event) => {
    if (menuPosition) {
      setAnchorEl(event.currentTarget);
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY + 20,
      left: event.pageX - 40,
    });
  };
  // const handleItemClick = (event) => {
  //   setMenuPosition(null);
  // };
  // const handleClose = (event) => {
  //   setAnchorEl(null);
  // };
  const handleLogout = async (event) => {
    global.service.logout().then((res) => {
      if (res.status) {
        global.session.remove("BearerToken");
        global.session.remove("profile");
        global.session.remove("hideHeader");
        global.session.remove("Gcm_token");
        global.session.remove("token");
        global.session.remove("time");
        sessionStorage.removeItem("excelData");
        sessionStorage.removeItem("uploadFile");
        sessionStorage.removeItem("reconcileData");
        sessionStorage.removeItem("reconcillation");
        window.location = "/";
      }
    });
  const messaging = getMessaging();
  try {
    await deleteToken(messaging);
    console.log("FCM token deleted successfully.");
  } catch (error) {
    console.error("Error deleting FCM token:", error);
}

  };
  const handleOpen = (event) => {};
  const [hasFetchedData, setHasFetchedData] = React.useState(false);
  // const data = sessionStorage.getItem("profile");
  const profile = storedProfile;
  let getUserType = localStorage.getItem("userType");
  const autoCompleteChangeHandler = (value, name) => {
    // setSpinner(true);
    // setLoading(true);
    let newField = _.cloneDeep(field);
    if(value.value){
      if (name === `allCategory`) {
        global.service.getSubCategories(value.value).then((res) => {
          newField[`subCategory`][`options`] = res?.data?.map((i) => {
            return { label: i?.alert_name, value: i?.id };
          });
          setField(newField);
        });
        newField[name]["value"] = value;
        setField(newField); 
        fetchNotifications(1, newField);
      }else{
        newField[name]["value"] = value;
        setField(newField); 
        fetchNotifications(1, newField);
      }
    }else if(value.value === ``){
      if(name === "subCategory"){
        newField[`subCategory`][`value`] = {label:"",value:""}
        setField(newField); 
        // setPage(1)
        fetchNotifications(1, newField);
      }else{
        newField[name]["value"] = ""
        newField[`subCategory`][`value`] = {label:"",value:""}
        newField[`subCategory`][`options`] = []
        setField(newField); 
        // setPage(1)
        fetchNotifications(1, newField);
      }
    }

    // fetchNotifications(1);
    // global.service.getAllAlerts(data).then((res) => {
    //   setSpinner(false);
    //   setAlertData(res?.data);
    // });
    // if (truckId || categoryId) {
    //   global.service.getAllAlerts(data).then((res) => {
    //     setLoading(false);
    //     // If the request is successful, update the alert data
    //     if (res?.data) {
    //       // setAlertData(res?.data);
    //       setNotifications(res?.data)
    //     }
    //   }).catch((error) => {
    //     setLoading(false)
    //     console.error("Error fetching alerts:", error);
    //   });
    // } else {
    //   setLoading(false);
    //   setNotifications([]);
    // }
  
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
    // If opening the drawer and data has not been fetched yet
    if (open && !hasFetchedData) {
      setSpinner(true);
      fetchNotifications(1); // Fetch initial notifications
      setHasFetchedData(true);

      let newField = _.cloneDeep(field);
      // let data = {
      //   page: 1,
      //   truck_id: "",
      //   alert_type_id: "",
      //   alert_subtype_id: "",
      // };

      let promise1 = global.service.getAllVehicles({
        ownVehicles: true,
        marketVehicles: true,
      });
      let promise2 = global.service.getAllCategories();
      // let promise3 = global.service.getAllAlerts(data);

      Promise.all([promise1, promise2]).then((res) => {
        setSpinner(false);
        if (res) {
          let vehicleOptions = res[0]?.vehicles?.map((i) => ({
            label: i?.registrationNumber,
            value: i?.id,
          }));
          newField["allVehicles"]["options"] = vehicleOptions;

          let categoryOptions = res[1]?.data?.map((i) => ({
            label: i?.alert_name,
            value: i?.id,
          }));
          newField["allCategory"]["options"] = categoryOptions;

          // setAlertData(res[2]?.data);
          setField(newField);
          setHasFetchedData(true); // Mark that data has been fetched
        }
      });
    }
  };
  const homeButtonClick = () =>{
    switch (profile?.role_id) {
      case 16:
        history.push('/Trip?tripType=ALL')
        break;
      case 17:
        history.push('/clients')
        break;
      case 18:
        window.location = "/fleetVendorPayments";
        break;
      case 19:
        window.location = "/FleetVendor";
        break;
      case 20:
        history.push('/drivers')
        break;
      default:
        history.push('/myFleet')
        break; 
    }
   
  }
  // const fetchNotifications = async (pageNumber) => {
  //   setLoading(true);
  //   try {
  //     const response = await global.service.getAllAlerts({
  //       page: pageNumber,
  //       truck_id: "",
  //       alert_type_id: "",
  //       alert_subtype_id: "",
  //     });
      
  //     setNotifications((prevNotifications) => [
  //       ...prevNotifications,
  //       ...response.data, // Append new notifications
  //     ]);
  //     setPage((prevPage) => prevPage + 1); // Increment page number for next call
  //   } catch (error) {
  //     console.error("Error fetching notifications:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const fetchNotifications = useCallback(async (pageNum,updatedField = field) => {
    // let newField = _.cloneDeep(field);
    setLoading(true);
    const data = {
      page: pageNum,
      truck_id: updatedField?.allVehicles?.value?.value || "",
      alert_type_id: updatedField?.allCategory?.value?.value || "",
      alert_subtype_id: updatedField?.subCategory?.value?.value || "",
    };
    try {
      const res = await global.service.getAllAlerts(data);
      if (res?.data) {
        setLoading(false);
        setNotifications((prevData) => pageNum > 1 ? [...prevData, ...res.data] : res.data)
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  }, [field,global.service]);
  useEffect(() => {
    if(hasFetchedData){

      fetchNotifications(page);
    }
  }, [page, fetchNotifications]);
  const handleScroll = ({ target }) => {
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100 && !loading) {
      setPage((prevPage) => prevPage + 1); // Load more at the bottom
    }
    if (target.scrollTop === 0 && !loading && page > 1) {
      setPage((prevPage) => prevPage - 1); // Load previous data when scrolling to top
    }
  };
  const handleRouteDeviation = (orderId,time,regi_no)=>{
    if(orderId){
        setRouteDeviatePopUp(true)
        setRouteDeviateData({orderId:orderId,alertTime:time,regiNo:regi_no})
      }
    console.log(orderId,`idddddd`)
  }
  let alert = loading ? (
    <CircularLoading />
  ) : notifications && notifications.length !== 0 ? (
    notifications.map((i) => {
      return (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex"}}>
            <Typography style={{ fontSize: 18, fontWeight: 900 }}>
              {i?.regi_no}
            </Typography>
              {i?.orderId && i?.orderId !== 0?<img src={deveation} style={{height:25,width:30,marginLeft:10,cursor:`pointer`}} onClick={()=>handleRouteDeviation(i?.orderId,i?.createdAtRead,i?.regi_no)}/>:null}
            </div>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: 100,
                color: "gray",
                marginRight: 10,
              }}
            >
              {i?.createdAtRead}
            </Typography>
          </div>
          <Typography
            style={{ color: "#f05f4c", fontSize: 16, fontWeight: 600 }}
          >
            {i?.alert_type}
          </Typography>
          <Typography style={{ fontSize: 14, fontWeight: 300 }}>
            <span style={{ fontWeight: 600 }}>{i?.alert_sub_type}</span> -{" "}
            {i?.description}
          </Typography>
          <Divider
            style={{ borderColor: "red", marginTop: 10, marginBottom: 10 }}
          />
        </>
      );
    })
  ) : (
    <>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography style={{ fontSize: 18, fontWeight: 900, color: "red" }}>
            No Data Found
          </Typography>
        </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: `column`,
          justifyContent: "center",
        }}
        className={classes.header}
      >
        <img src={Image} alt="image" style={{ width: "95%", margin: "20px" }} />
        <Typography
          style={{
            fontSize: "16px",
            textAlign: "center",
            color: "#707070",
            margin: "30px 0px",
          }}
        >
          Unfortunately, we didn't find any results.
        </Typography>
        <Typography
          style={{
            color: "#397397",
            fontSize: "22px",
            border: "1px solid #FFFFFF",
            textAlign: "center",
            width: "40%",
            borderRadius: "5px",
            margin: "auto",
            boxShadow: "2px 2px 2px 2px #e4e4e4",
            marginBottom: "15px",
          }}
        >
          NO DATA FOUND
        </Typography>
      </div>
    </>
  );
  const list = (anchor) => (
    <Box
      sx={{ width: 500, overflowY: "hidden", overflowX: "hidden" }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem style={{ marginLeft: 27, marginBottom: "-19px" }}>
          <Typography
            style={{
              fontSize: "25px",
              letterSpacing: "0px",
              color: "rgb(78, 76, 76)",
              opacity: 1,
              fontWeight: "bold",
            }}
          >
            {props && props?.location?.state?.vehiclePlate}
          </Typography>
        </ListItem>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: 15,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "25px",
                  letterSpacing: "0px",
                  color: "#4E4C4C",
                  opacity: 1,
                  fontWeight: "bold",
                  textDecoration: "",
                }}
              >
                Notifications
                {/* NotificationsIcon */}
              </Typography>
            </div>
            <NotificationsIcons />
          </div>
          <Grid
            container
            spacing={2}
            className={classes.form_item}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <Grid item xs={4}>
              <Fields.AntSelectableSearchField
                fieldData={field.allVehicles}
                autoCompleteChangeHandler={autoCompleteChangeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <Fields.AntSelectableSearchField
                fieldData={field.allCategory}
                autoCompleteChangeHandler={autoCompleteChangeHandler}
              />
            </Grid>
            <Grid item xs={3.5}>
              <Fields.AntSelectableSearchField
                fieldData={field.subCategory}
                autoCompleteChangeHandler={autoCompleteChangeHandler}
              />
            </Grid>
          </Grid>
        </div>
        <Divider style={{ paddingTop: 10 }} />
        <div
          style={{
            margin: "25px",
            // marginTop: 40,
          }}
        >
          {/* <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ width: "100%", height: window.innerHeight }}
            renderThumbVertical={({ style }) => (
              <div
                style={{
                  ...style,
                  background: "#366e93",
                  borderRadius: "10px",
                }}
              />
            )}
            onScroll={handleScroll}
          >
            {alert}
          </Scrollbars> */}
          <Scrollbars onScroll={handleScroll} style={{ height: '80vh' }}>
          {alert}
        </Scrollbars>
        </div>
      </List>
    </Box>
  );
  const handlePopupClose = ()=>{
    setRouteDeviatePopUp(false)
  }
  return (
    <>
      <div key={props?.ownerId} className={classes.header}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <img src={suvega} className={classes.image} onClick={homeButtonClick}/>
            {getUserType === "owner" && <AppMenu />}
            <div className={classes.grow} />
            {auth && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {window.innerWidth > 450 ? (
                  <Typography className={classes.name}>{userName}</Typography>
                ) : null}
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleItemsClick}
                  color="inherit"
                >
                  <img
                    src={
                      profilepicBase64
                        ? "data:image/png;base64," + profilepicBase64
                        : man
                    }
                    onClick={handleOpen}
                    style={{ width: 40, height: 40 }}
                  />
                </IconButton>
                <Menu
                  open={!!menuPosition}
                  onClose={() => setMenuPosition(null)}
                  anchorReference="anchorPosition"
                  anchorPosition={menuPosition}
                >
                  <MenuItem>
                    <Typography className={classes.name}>{userName}</Typography>
                  </MenuItem>
                  {profile?.role_id == 0 ? (
                    <MenuItem
                      className={classes.logout}
                      onClick={() => history.push("./edit-profile")}
                    >
                      Profile
                    </MenuItem>
                  ) : null}
                   {(profile?.role_id != 16 && profile?.role_id != 17 && profile?.role_id != 18 && profile?.role_id != 19 && profile?.role_id != 20 ) ? (<MenuItem
                    className={classes.logout}
                    onClick={() => history.push("./benchmark")}
                  >
                    Benckmark
                  </MenuItem>
                  ) : null}
                  {(profile?.role_id != 16 && profile?.role_id != 17 && profile?.role_id != 18 && profile?.role_id != 19 && profile?.role_id != 20 ) ? (<MenuItem
                    className={classes.logout}
                    onClick={() => history.push("./equipments")}
                  >
                    Inventory Category
                  </MenuItem>
                  ) : null}
                  {profile?.role_id == 0 ? (
                    <MenuItem
                      className={classes.logout}
                      onClick={() => history.push("./category")}
                    >
                      Reasoning
                    </MenuItem>
                  ) : null}
                   {(profile?.role_id != 16 && profile?.role_id != 17 && profile?.role_id != 18 && profile?.role_id != 19 && profile?.role_id != 20 ) ? (<MenuItem
                    className={classes.logout}
                    onClick={() => history.push("./department")}
                  >
                    Department
                  </MenuItem>
                  ) : null}
                   {(profile?.role_id != 16 && profile?.role_id != 17 && profile?.role_id != 18 && profile?.role_id != 19 && profile?.role_id != 20 ) ? (<MenuItem
                    className={classes.logout}
                    onClick={() => history.push("./role")}
                  >
                    Role
                  </MenuItem>
                  ) : null}
                  <MenuItem className={classes.logout} onClick={handleLogout}>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Tooltip title="Notifications">
                  {/* <ListIcon
                          style={{ fontSize: "36px", color: "#366e93",cursor: "pointer", }}
                          onClick={toggleDrawer(anchor, true)}
                        /> */}
                  <Stack spacing={2} direction="row">
                    <Badge
                      // badgeContent={alertData === undefined || alertData?.length === 0 ? "" : alertData?.length}
                      badgeContent={
                        alertData === undefined || alertData?.length === 0
                          ? ""
                          : ""
                      }
                      color="error"
                    >
                      <NotificationsIcons
                        style={{ color: "white", cursor: "pointer" }}
                        onClick={toggleDrawer(anchor, true)}
                      />
                    </Badge>
                  </Stack>
                </Tooltip>
                <SwipeableDrawer
                  anchor={anchor}
                  open={drawer[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  // style={{scroll}}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </Toolbar>
        </AppBar>
      </div>
      <RouteDeviationMap open={routeDeviatePopUp} onClose={handlePopupClose} routeDeviateData={routeDeviateData}/> 
    </>
  );
}
