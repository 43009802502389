import React from "react";
import "antd/dist/antd.css";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

export default function DateRangePicker(props) {
  const onDateSelect = (values) => {
    props.onDateSelect(values);
  };
  return (
        <Space 
        direction="vertical" 
        size={15}
        align='center'
        >
      <RangePicker
      style={{height:40}}
        renderExtraFooter={() => ""}
        showTime
        onOk={onDateSelect}
        value={props.value}
        format="DD-MM-yyyy HH:mm:ss"
        disabledDate={props?.fieldData?.disabledDate}
      />
    </Space>
  );
}