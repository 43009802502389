import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import "../../common.css";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import * as Fields from "../../sharedComponents";
import { Grid } from "@material-ui/core";
import ValidateFields from "../../validations/validateFields";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import moment from "moment";
import Util from "../../utils";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles = makeStyles(() => ({
    addUser_btn: {
        textTransform: "none",
        backgroundColor: "#649B42",
        "&:hover": {
            backgroundColor: "#649B42",
        },
        padding: "8px 15px",
        marginRight: "12px",
    },
    header_buttons: {
        padding: "0px 15px",
    },
    site_button: {
        background: "#649B42",
        textTransform: "none",
        color: "#FFFFFF",
        marginRight: "15px",
        padding: "8px 15px",
        "&:hover": {
            background: "#649B42",
        },
    },
    clear_button: {
        padding: "6px 15px",
        background: "#D3710F0D",
        border: "1px solid #D3710F",
        color: "#D3710F",
        "&:hover": {
            background: "#D3710F0D",
        },
    },
    filter_buttons: {
        textAlign: "right",
        padding: "0px 15px",
    },
    dialogPaper: {
        minWidth: '500px', // Set the minimum width to 300px
        maxWidth: '600px', // Set the maximum width to 500px
    },
    divider: {
        borderTop: '2px solid #F15F4C', // Customize the thickness and color of the divider
    },
    dialogTitle: {
        cursor: 'move',
        textAlign: 'center',
        color: '#F15F4C'
    }
}));
export const AssignInstaller = (props) => {
    const formFields = {

        deviceImei: {
            name: "deviceImei",
            label: "Device (IMEI) Number",
            value: { value: "", label: "" },
            options: [],
            topLabel: true,
            validationRequired: true,
            validPattern: "ALPHABET_with_numeric_label",
            isValid: true,
            errorMsg: "Please select device type",
            ref: useRef(),
            allowClear: true,
            labelColor: "#397296",
            // image: installer,
        },


        installer: {
            name: "installer",
            label: "Installer",
            value: { value: "", label: "" },
            options: [],
            topLabel: true,
            validationRequired: true,
            validPattern: 'ALPHABET_with_numeric_label',
            isValid: true,
            errorMsg: "Please select installer",
            ref: useRef(),
            allowClear: true,
            //   labelColor: "#397296",
            //   image: installer,
        },
        approvedBy: {
            name: "approvedBy",
            label: "Approved By",
            value: "",
            isValid: true,
            topLabel: true,
            validationRequired: true,
            validPattern: "ALPHA_NUMERIC_SPACE",
            errorMsg: "Please enter Approver name",
            ref: useRef(),
            // labelColor: "#397296",
            // image: simBasketName,
        },
    };
    const [fields, setFields] = useState(formFields);
    const util = new Util()
    const [open, setOpen] = useState(props.open);
    const [alertData, setAlertData] = useState({
        open: false,
        severity: "",
        message: "",
    });
    const closeAlert = () => {
        let alert = _.cloneDeep(alertData);
        alert.open = false;
        alert.severity = "";
        alert.message = "";
        setAlertData(alert);
    };
    let classes = useStyles();
    let placeRefCircle = useRef();
    const [spinner, setSpinner] = useState(false);
    const fileInputRef = useRef(null);
    const validateFields = new ValidateFields();
    const Service = global.service
    useEffect(async () => {
        let newFields = _.cloneDeep(fields);
        if (props.assignData) {
            newFields.deviceImei.value = { label: props?.assignData?.deviceId, value: props?.assignData?.deviceImei }


            if (props.assignData.serviceProvideName != null && props.assignData.serviceProvideName != undefined && props.assignData.serviceProvideName != null) {
                newFields.installer.value = {label:props.assignData.serviceProviderId,value:props.assignData.serviceProvideName}
            }
            if (props.assignData.approvedBy != null && props.assignData.approvedBy != undefined && props.assignData.approvedBy != null) {
                newFields.approvedBy.value = props.assignData.approvedBy
            }
        }

        await Service.getInstallerData(props.assignData.companyId)
            .then((res) => {
                if (res.data.length > 0) {
                    let installerOptions = res.data.map((each) => {
                        return { label: each.first_name, value: each.id };
                    });
                    newFields.installer.options = installerOptions
                }
            })
            .catch((e) => {
                console.log("Error......", e);
            });
        setFields(newFields)
    }, [props?.assignData])

    const autoCompleteChangeHandler = async (value, name) => {
        let newFields = _.cloneDeep(fields);
        if (newFields[name]["validationRequired"] && value.label.length > 0) {
            newFields[name]["isValid"] = true;
        }
        newFields[name]["value"] = value;
        setFields(newFields);
    };

    const inputChangeHandler = (value, name) => {
        let newFields = _.cloneDeep(fields);
        if (newFields[name]["validationRequired"] && value.length > 0) {
            newFields[name]["isValid"] = true;
        }
        newFields[name]["value"] = value;
        setFields(newFields);
    };


    const subscriptionOfDevice = async (fields) => {
        let dataStatus = await validateFields.validateFieldsData(fields);
        if (dataStatus.status) {
            setSpinner(true)
            let object = {
                deviceId: props.assignData.deviceId,
                // lostDate : util.formatDate(fields.lostDate.value)
                approvedBy: fields.approvedBy.value,
                installerId: (props.assignData.serviceProviderId != null && props.assignData.serviceProviderId != undefined && fields.installer?.value?.value !== props.assignData.serviceProviderId) ? props.assignData.serviceProviderId : fields.installer?.value?.value,
            };
            await Service.assignInstaller(object)
                .then((res) => {
                    setSpinner(false);
                    if (res.status) {
                        setAlertData({
                            open: true,
                            severity: "success",
                            message: res.message,
                        });
                        setTimeout(() => {
                            closeAlert();
                            handleClose();
                            window.location.reload();
                        }, 3000);
                    } else {
                        setAlertData({
                            open: true,
                            severity: "error",
                            message: res.message,
                        });
                    }
                })
                .catch((e) => {
                    console.log("Error......", e);
                });
        } else {
            let newFields = _.cloneDeep(fields);
            let erroredFields = Object.keys(dataStatus.data).filter(
                (key, i) => dataStatus.data[key].isValid === false
            );
            newFields = dataStatus.data;
            setFields(newFields);
        }
        // props.onClose();
    };
    const handleClose = () => {
        setOpen(false);
        props.onClose();
        let newField = _.cloneDeep(fields);
        setFields(newField);
    };

    return (
        <div >
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
                fullWidth={true}
            >
                {alertData.open ? (
                    <div className={classes.alertBox}>
                        <AlertMessage
                            severity={alertData.severity}
                            message={alertData.message}
                            closeAlert={closeAlert}
                        />
                    </div>
                ) : null}
                <DialogTitle
                    className={classes.dialogTitle}
                    id="draggable-dialog-title"
                >
                    Assign Installer
                </DialogTitle>
                <DialogContent dividers className={classes.divider}>
                    <DialogContentText>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={12} >
                                <Fields.AntSelectableSearchField
                                    fieldData={fields.deviceImei}
                                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                                    variant="outlined"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Fields.AntSelectableSearchField
                                    fieldData={fields.installer}
                                    autoCompleteChangeHandler={(value, name) =>
                                        autoCompleteChangeHandler(value, name)
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Fields.InputField
                                    fieldData={fields.approvedBy}
                                    inputChangeHandler={inputChangeHandler}
                                    variant="outlined"
                                />
                            </Grid>




                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => forSubmitingUserData(fields)}
                  color="primary"
                >
                  Submit
                </Button> */}
                    <Grid
                        container
                        spacing={2}
                        className="details_container_content"
                        style={{ marginRight: 25 }}
                    >
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}></Grid>
                                {/* <Grid item xs={3}></Grid> */}
                                {/* <Grid item xs={2}></Grid> */}
                                <Grid item xs={2}>
                                    <Button
                                        variant="outlined"
                                        onClick={handleClose}
                                        className="cancel_button"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        className="save_button"
                                        onClick={() => subscriptionOfDevice(fields)}
                                        startIcon={
                                            spinner ? (
                                                <CircularProgress size={20} color={"#fff"} />
                                            ) : null
                                        }
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};
